<template>
  <div>
    <el-row :gutter="20" style="padding: 20px">
      <el-col :span="4">
        <h3>
          请选择组件<button
            type="button"
            class="btn btn-info h30 w-md mr-3"
            @click="addComp"
          >
            增加组件
          </button>
        </h3>
        <draggable
          class="dragArea list-group"
          :list="list1"
          :clone="clone"
          :group="{ name: 'people', pull: pullFunction }"
          @start="start"
        >
          <div
            class="list-group-item"
            v-for="element in list1"
            :key="element.id"
          >
            <div class="style-info flexList justify-content-between">
              <div>
                <i class="iconfont icon-riLine-team-line mr-2 font-size-18"></i
                >{{ element.name }}
              </div>
              <div class="grey-time" v-if="element.type == 'input'">输入框</div>
              <div class="grey-time" v-if="element.type == 'select'">
                下拉框
              </div>
              <div class="grey-time" v-if="element.type == 'switch'">开关</div>
              <div class="grey-time" v-if="element.type == 'radio'">单选框</div>
              <div class="grey-time" v-if="element.type == 'selectEx'">
                级联下拉框
              </div>
            </div>
          </div>
        </draggable>
      </el-col>
      <el-col :span="16">
        <h3>请将组件放入</h3>
        <div class="mt-3 exam-form">
          <el-row style="max-height: 314px; line-height: 314px">
            <el-col style="width: 66%" ref="list1Container">
              <draggable
                class="msgContent flexList fs-xs"
                :list="list2"
                group="people"
                @add="addEnd"
              >
                <div
                  @click="chooseEle(element)"
                  class="flexList msgLabel"
                  :class="element.width"
                  v-for="element in list2"
                  :key="element.id"
                >
                  <div class="msgName">
                    <span style="color: red" v-if="element.must">*</span
                    >{{ element.name }}
                  </div>
                  <div class="msgVal selectShow flexList">
                    <input
                      v-model="form[element.bound]"
                      :placeholder="element.name"
                      v-if="element.type == 'input'"
                      class="form-control w-100 h30"
                    />
                    <input
                      type="textarea"
                      v-model="form[element.bound]"
                      :placeholder="element.name"
                      v-if="element.type == 'textarea'"
                      class="form-control w-100 h30"
                    />
                    <yz-select
                      :dict="element.data"
                      :bound="element.bound"
                      v-model="form"
                      v-if="element.type == 'select'"
                    ></yz-select>
                    <yz-select-mulit
                      v-model="form"
                      :dict="element.data"
                      :bounds="element.bound"
                      v-if="element.type == 'selectEx'"
                    ></yz-select-mulit>
                    <b-form-checkbox
                      v-if="element.type == 'switch'"
                      v-model="form[element.bound]"
                      switch
                      class="switch-check"
                    >
                    </b-form-checkbox>
                    <el-radio-group
                      v-if="element.type == 'radio'"
                      v-model="form[element.bound]"
                    >
                      <el-radio
                        :label="item.value"
                        v-for="item in element.data"
                        :key="item.value"
                        >{{ item.name }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
              </draggable>
            </el-col>
            <el-col style="width: 33%; text-align: center">
              <img
                :src="require('@/assets/images/tx.jpeg')"
                alt=""
                style="width: 160px; height: 240px"
              />
            </el-col>
          </el-row>
          <el-row>
            <draggable
              style="min-height: 45px; width: 100%; flex-wrap: wrap"
              class="flexList fs-xs"
              :list="list3"
              @add="addEnd"
              group="people"
            >
              <div
                @click="chooseEle(element)"
                class="flexList msgLabel"
                :class="element.width"
                v-for="element in list3"
                :key="element.id"
              >
                <div class="msgName">
                  <span style="color: red" v-if="element.must">*</span
                  >{{ element.name }}
                </div>
                <div class="msgVal selectShow flexList">
                  <input
                    v-model="form[element.bound]"
                    :placeholder="element.name"
                    v-if="element.type == 'input'"
                    class="form-control w-100 h30"
                  />
                  <input
                    v-model="form[element.bound]"
                    :placeholder="element.name"
                    v-if="element.type == 'textarea'"
                    class="form-control w-100 h30"
                  />
                  <yz-select
                    :dict="element.data"
                    :bound="element.bound"
                    v-model="form"
                    v-if="element.type == 'select'"
                  ></yz-select>
                  <yz-select-mulit
                    v-model="form"
                    :dict="element.data"
                    :bounds="element.bound"
                    v-if="element.type == 'selectEx'"
                  ></yz-select-mulit>
                  <b-form-checkbox
                    v-if="element.type == 'switch'"
                    v-model="form[element.bound]"
                    switch
                    class="switch-check"
                  >
                  </b-form-checkbox>
                  <el-radio-group
                    v-if="element.type == 'radio'"
                    v-model="form[element.bound]"
                  >
                    <el-radio
                      :label="item.value"
                      v-for="item in element.data"
                      :key="item.value"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </draggable>
          </el-row>
        </div>
      </el-col>
      <el-col :span="4" v-if="chooseId.id != undfinded">
        <h3>选择属性</h3>
        <div class="mt-3 exam-form">
          <div class="check-distri mb-3">
            <label>宽度百分比：({{ chooseId.name }})</label>
            <div class="col-sm-10 p-0 flexList">
              <yz-select
                :dict="[
                  { name: '20%', value: 'w20' },
                  { name: '33%', value: 'w33' },
                  { name: '50%', value: 'w50' },
                  { name: '66%', value: 'w66' },
                  { name: '100%', value: 'w100' },
                ]"
                :bound="['width']"
                v-model="chooseId"
              ></yz-select>
            </div>
          </div>
          <div class="check-distri mb-3">
            <label>是否必填：</label>
            <div class="col-sm-10 p-0 flexList">
              <yz-select
                :dict="[
                  { name: '必填', value: true },
                  { name: '非必填', value: false },
                ]"
                :bound="['must']"
                v-model="chooseId"
              ></yz-select>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-button @click="success">提交</el-button>

    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal
      v-model="showComp"
      centered
      title="增加组件"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">类型：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '输入框', value: 'input' },
                { name: '文本域', value: 'textarea' },
                { name: '下拉', value: 'select' },
                { name: '级联下拉', value: 'selectEx' },
                { name: '开关', value: 'switch' },
                { name: '单选', value: 'radio' },
              ]"
              :bound="['type']"
              v-model="addForm"
            ></yz-select>
          </div>
        </div>
        <div
          class="flexList check-distri mb-3"
          v-if="'select' == addForm.type || 'selectEx' == addForm.type"
        >
          <label class="mb-0" style="width: 15%">选择字典：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '民族 dict_mz', value: 'dict_mz' },
                { name: '性别 dict_xb', value: 'dict_xb' },
                { name: '政治面貌 dict_zzmm', value: 'dict_zzmm' },
                { name: '婚姻状况 dict_hyzt', value: 'dict_hyzt' },
              ]"
              :bound="['dict']"
              v-model="addForm"
            ></yz-select>
          </div>
        </div>
        <div
          class="flexList check-distri mb-3"
          v-if="'input' == addForm.type || 'textarea' == addForm.type"
        >
          <label class="mb-0" style="width: 15%">最大长度：</label>
          <div class="col-sm-10 p-0 flexList">
            <input
              type="number"
              v-model="addForm.length"
              class="form-control w-70 h30"
            />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">标题：</label>
          <div class="col-sm-10 p-0 flexList">
            <input v-model="addForm.name" class="form-control w-70 h30" />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">提示信息：</label>
          <div class="col-sm-10 p-0 flexList">
            <input
              v-model="addForm.placeholder"
              class="form-control w-70 h30"
            />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">是否必填：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '必填', value: true },
                { name: '非必填', value: false },
              ]"
              :bound="['must']"
              v-model="addForm"
            ></yz-select>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">绑定字段(最多2个)</label>
          <div class="col-sm-10 p-0 flexList">
            <input v-model="addForm.bound" class="form-control w-70 h30" />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 15%">宽度：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '20%', value: 'w20' },
                { name: '33%', value: 'w33' },
                { name: '50%', value: 'w50' },
                { name: '66%', value: 'w66' },
                { name: '100%', value: 'w100' },
              ]"
              :bound="['width']"
              v-model="addForm"
            ></yz-select>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="checkAddComp"
        >
          确定添加
        </button>
        <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showComp = false"
        >
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束-->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import YzSelect from "@/components/form/yzSelect.vue";
import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
import { getDicts } from "@/api/common/dict.js";
import { covertToSql } from "@/api/common/draggable.js";
// let idGlobal = 8;
export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  components: {
    draggable,
    YzSelect,
    YzSelectMulit,
  },
  data() {
    return {
      form: {},
      chooseId: {},
      list1: [],
      list2: [],
      list3: [],
      controlOnStart: true,
      showComp: false,
      addForm: {
        name: "",
        icon: "",
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
    };
  },
  methods: {
    chooseEle(e) {
      this.chooseId = e;
    },
    wChange() {
      this.$forceUpdate();
    },
    clone(e) {
      return JSON.parse(JSON.stringify(e));
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    addEnd(e) {
      if (this.$refs["list1Container"].$el.clientHeight > 350) {
        var a = this.list2[e.newIndex];
        this.list2.splice(e.newIndex, 1);
        this.list1.push(a);
      }
    },
    addComp() {
      this.showComp = true;
      this.addForm = {
        name: "",
        icon: "",
        must: false,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "",
      };
    },
    checkAddComp() {
      this.list1.push(this.addForm);
      this.showComp = false;
      this.loadDict();
    },
    // 提交创建好的模板
    success() {
      this.listChoose = [];
      this.list2.forEach((item) => {
        item["listId"] = 1;
        this.listChoose.push(item);
      });
      this.list3.forEach((item) => {
        item["listId"] = 2;
        this.listChoose.push(item);
      });
      console.log(covertToSql(this.listChoose));
    },
    // 加载字典数据
    loadDict() {
      let idx = [];
      let dicts = [];
      for (let i in this.list1) {
        let item = this.list1[i];
        if (item.dict) {
          idx.push(i);
          dicts.push(item.dict);
        }
      }
      getDicts(...dicts).then((res) => {
        for (let i in dicts) {
          let id = idx[i];
          let dictName = dicts[i];
          this.list1[id]["data"] = res[dictName];
        }
      });
    },
  },
  mounted() {
    this.list1 = [
      {
        name: "个人承诺2",
        icon: "",
        must: true,
        id: -1,
        type: "input",
        width: "w100",
        bound: "grcl2",
      },
      {
        name: "个人承诺",
        icon: "",
        must: true,
        id: 0,
        type: "input",
        width: "w100",
        bound: "grcl",
      },
      {
        name: "民族",
        icon: "",
        must: true,
        id: 1,
        type: "select",
        width: "w50",
        bound: ["mzm", "mzmc"],
        dict: "dict_mz",
      },
      {
        name: "性别",
        icon: "",
        id: 2,
        must: true,
        type: "select",
        width: "w50",
        bound: ["xbm", "xbmc"],
        dict: "dict_xb",
      },
      {
        name: "手机号",
        icon: "",
        id: 3,
        must: true,
        type: "input",
        width: "w50",
        bound: "sjh",
      },
      {
        name: "政治面貌",
        icon: "",
        id: 4,
        must: true,
        type: "select",
        width: "w50",
        bound: ["zzmmm", "zzmmjc"],
        dict: "dict_zzmm",
      },
      {
        name: "籍贯",
        icon: "",
        id: 5,
        must: true,
        type: "input",
        width: "w50",
        bound: "jg",
      },
      {
        name: "出生地",
        icon: "",
        id: 6,
        must: true,
        type: "selectEx",
        width: "w100",
        dict: "dict_xzqh",
        bound: [
          ["ssdm", "ssmc"],
          ["djsdm", "djsmc"],
          ["qxdm", "qxmc"],
        ],
      },
      {
        name: "婚姻状况",
        icon: "",
        id: 7,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况",
        icon: "",
        id: 8,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况1",
        icon: "",
        id: 9,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况2",
        icon: "",
        id: 10,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况3",
        icon: "",
        id: 11,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况4",
        icon: "",
        id: 12,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      {
        name: "婚姻状况5",
        icon: "",
        id: 13,
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
    ];
    this.loadDict();
  },
};
</script>
<style scoped>
.w20 {
  width: 20%;
}
.w33 {
  width: 33%;
}
.w50 {
  width: 50%;
}
.w66 {
  width: 66%;
}
.w100 {
  width: 100%;
}
.dragArea {
  padding: 30px 0;
  display: flex;
}
.msgName {
  min-width: 30%;
  width: 30%;
}
.heighLabel {
  height: 132px;
  line-height: 132px;
}
</style>
