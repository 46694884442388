import { render, staticRenderFns } from "./drog-test.vue?vue&type=template&id=0556e276&scoped=true&"
import script from "./drog-test.vue?vue&type=script&lang=js&"
export * from "./drog-test.vue?vue&type=script&lang=js&"
import style0 from "./drog-test.vue?vue&type=style&index=0&id=0556e276&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0556e276",
  null
  
)

export default component.exports